import React from 'react';
import { ContactUsForm, Seo } from 'components/common';
import { siteUrl } from 'configs/constants';

function ContactUsPage() {
  return (
    <>
      <Seo title="Contact Us" url={`${siteUrl}/contact-us`} />
      <ContactUsForm />
    </>
  );
}

export default ContactUsPage;
